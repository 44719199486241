// extracted by mini-css-extract-plugin
export var blogCredits = "post-feed-module--blogCredits--939a0";
export var blogPost = "post-feed-module--blogPost--1f5c4";
export var blogPostContent = "post-feed-module--blogPostContent--34a30";
export var decoration = "post-feed-module--decoration--baa7b";
export var heading = "post-feed-module--heading--24fab";
export var imgHolder = "post-feed-module--imgHolder--545e1";
export var overlay = "post-feed-module--overlay--7c5a5";
export var postHeader = "post-feed-module--postHeader--37107";
export var postSpacing = "post-feed-module--post-spacing--71aec";
export var rowSpacing = "post-feed-module--row-spacing--b0e03";
export var rowSpacingSml = "post-feed-module--row-spacing-sml--063df";
export var sectionSpacing = "post-feed-module--section-spacing--86ff6";
export var titleBox = "post-feed-module--titleBox--2d695";