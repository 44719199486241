// import dependencies
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// import components
import Layout from "../components/layout";
import PostNavButtons from "../components/posts-nav-buttons";
import Section from "../components/section";

// import styles and assets
import * as styles from "./styles/post-feed.module.scss";

export default function BlogPost({ data }) {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <Section>
                <PostNavButtons />
                <div className={styles.blogPost}>
                    <div className={styles.postHeader}>
                        <div className={styles.imgHolder}>
                            <GatsbyImage
                                image={
                                    post.frontmatter.featuredImage
                                        .childImageSharp.gatsbyImageData
                                }
                                alt={post.frontmatter.title}
                            />
                            <div className={styles.overlay}></div>
                            <div className={styles.heading}>
                                <div className={styles.decoration}></div>
                                <span>
                                    {post.frontmatter.date.slice(0, 10)}
                                </span>
                            </div>
                            <div className={styles.titleBox}>
                                <h2>{post.frontmatter.title}</h2>
                            </div>
                        </div>
                        <div
                            className={styles.blogPostContent}
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        />
                        <div className={styles.blogPostContent}>
                            <div>
                                <span className={styles.blogCredits}>
                                    Written by: {post.frontmatter.author} ///
                                    Edited by: {post.frontmatter.editor}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    );
}
export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date
                path
                title
                author
                editor
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            height: 380
                            formats: [WEBP]
                        )
                    }
                }
            }
        }
    }
`;
